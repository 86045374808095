import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Los 17 de Kabul</Title>
                <Text>
                    El 15 de agosto de 2021 los talibanes se hicieron con el control de Kabul,
                    retomando el poder en Afganistán tras 20 años.
                    <br />
                    <br />
                    La evacuación de miles de civiles se convierte en la prioridad de las potencias
                    internacionales.
                    <br />
                    <br />
                    Aaqila, colaboradora de la embajada española, comienza su huida desesperada
                    hacia la libertad.
                    <br />
                    <br />
                    17 agentes son destinados de manera urgente al aeropuerto de Kabul para ejecutar
                    las tareas de evacuación.
                </Text>

                {/** Preview del libro */}
                <div className="container lg:px-0 py-20">
                    <Title>Preview del libro</Title>
                    <Text className="text-justify">
                        A continuación le adjuntamos un resumen en formato pdf del primer capítulo
                        del libro.
                    </Text>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/242/book.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                </div>

                {/** Cómo adquirirlo */}
                <div className="bg-gray-100 bg-opacity-50">
                    <div className="container lg:px-0 py-20 ">
                        <Title>¿Cómo puedo adquirirlo?</Title>
                        <Text className="text-justify">
                            Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                            <Link to="/tienda" className="default-link">
                                Tienda
                            </Link>{" "}
                            haciendo clic en el <strong>botón de añadir a la cesta</strong>.
                            Recuerda que puedes combinar diferentes productos en una misma compra.
                            <br />
                            <br />
                            Una vez recibamos la orden de compra procederemos al envío del libro. El
                            envío se realiza a través de la empresa GLS en la modalidad de envío
                            certificado. La empresa GLS os enviará un mail para que podáis saber en
                            todo momento donde se encuentra vuestro libro y si es necesario os
                            llamarán por teléfono para contactar con vosotros. El tiempo en ser
                            entregado es aproximadamente 48 horas.
                            <div className="text-left text-xs font-montserrat italic mt-10">
                                * Nos comprometemos en realizar el envío el día siguiente de
                                realizar el pago aunque no podemos hacernos responsables de los
                                posibles retrasos que la empresa transportista pueda ocasionar.
                            </div>
                        </Text>
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
